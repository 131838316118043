
import { getClientCoordinates } from 'common-utils/mouse';
import { useSync } from 'hooks/useSync';
import React, { createContext, useState, useEffect } from 'react';

const MouseContext = createContext();

const eventOptions = {
  capture: true,
  passive: true,
};

const MouseContextProvider = ({ children }) => {
  const [getMousePosition, setMousePosition] = useSync({ x: 0, y: 0 });
  const [mouseState, setMouseState] = useState('up');

  const mousePosition = {
    get x() {
      return getMousePosition().x;
    },
    get y() {
      return getMousePosition().y;
    },
  };

  const handleMouseMove = (event) => {
    if (event.buttons > 0 && mouseState === 'up') {
      setMouseState('down');
    } else if (event.buttons === 0 && mouseState === 'down') {
      setMouseState('up');
    }

    const { clientX, clientY } = getClientCoordinates(event);

    setMousePosition({ x: clientX, y: clientY });
  };

  const handleMouseDown = () => {
    setMouseState('down');
  };

  const handleMouseUp = () => {
    setMouseState('up');
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove, eventOptions);
    window.addEventListener('mousedown', handleMouseDown, eventOptions);
    window.addEventListener('mouseup', handleMouseUp, eventOptions);
    window.addEventListener('dragend', handleMouseUp, eventOptions);
    window.addEventListener('touchstart', handleMouseDown, eventOptions);
    window.addEventListener('touchend', handleMouseUp, eventOptions);
    window.addEventListener('touchmove', handleMouseMove, eventOptions);
    window.addEventListener('touchcancel', handleMouseUp, eventOptions);


    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('dragend', handleMouseUp);
      window.removeEventListener('touchstart', handleMouseDown);
      window.removeEventListener('touchend', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchcancel', handleMouseUp);
    };
  }, []);

  return (
    <MouseContext.Provider value={{ mousePosition, mouseState }}>
      {children}
    </MouseContext.Provider>
  );
};

export { MouseContext, MouseContextProvider };
