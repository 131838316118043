/**
 * Gets client coordinates from either mouse or touch events
 * @param {Event} e - The event object (mouse or touch)
 * @param {Object} [position] - Optional position object from react-draggable
 * @returns {Object} Object containing clientX and clientY coordinates
 */
export const getClientCoordinates = (e) => {
  // Default coordinates
  let clientX = null;
  let clientY = null;

  // Handle touch events
  if (e.touches && e.touches.length > 0) {
    // Active touch during touchmove or touchstart
    clientX = e.touches[0].clientX;
    clientY = e.touches[0].clientY;
  } else if (e.changedTouches && e.changedTouches.length > 0) {
    // Touch that ended (touchend event)
    clientX = e.changedTouches[0].clientX;
    clientY = e.changedTouches[0].clientY;
  } else if (e && Number.isFinite(e.clientX) && Number.isFinite(e.clientY)) {
    // Mouse events
    clientX = e.clientX;
    clientY = e.clientY;
  }

  return { clientX, clientY };
};
